import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyD_zbhd5oEecKe3HyxHxLiJELFalMUAT4A",
  authDomain: "self-made-3a554.firebaseapp.com",
  databaseURL: "https://self-made-3a554.firebaseio.com",
  projectId: "self-made-3a554",
  storageBucket: "self-made-3a554.appspot.com",
  messagingSenderId: "555407331819",
  appId: "1:555407331819:web:a3ead1a1acaf98462949ae",
  measurementId: "G-Q0H2DF12V3"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
export const db   = firebase.firestore();
export const auth = firebase.auth();


// export const app = firebase.auth;
// export const db  = firebase.database();